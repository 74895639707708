import React from "react";

import Layout from "../components/Layout503";
import { Unavailable } from "../contents/503";

const IndexPage = (props) => {
  return (
    <div className="main-page">
      <Layout location={props.location} locale="es">
        <Unavailable {...{ props }} />
      </Layout>
    </div>
  );
};

export default IndexPage;
